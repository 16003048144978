import React, { useEffect } from 'react'

import { isRunningInBrowser } from 'utils/tuixLocalStorage'
import Page from 'components/Page'
import Section from 'components/Section'
import UnsubscribeMessage from 'components/UnsubscribeRes'

import './unsubscribe.scss'
import tuixClientApi from 'utils/tuixClientApi'

export default (): JSX.Element => {
    const [message, setMessage] = React.useState('')

    let userId: string
    if (isRunningInBrowser) {
        const params = new URLSearchParams(window.location.search)
        userId = params.get('userId')
    }

    useEffect(() => {
        const unsubscribe = async () => {
            if (userId) {
                try {
                    await tuixClientApi.deleteSubscription({ id: userId })
                    setMessage('You have been unsubscribed from all emails.')
                } catch (e) {
                    setMessage('Something went wrong. Please try again.')
                }
            }
        }
        unsubscribe()
    }, [userId])

    return (
        <Page isSinglePage={true}>
            <Section id="unsubscribe">
                <UnsubscribeMessage message={message} />
            </Section>
        </Page>
    )
}
